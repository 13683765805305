.ticker-wrapper {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
  }
  
  .ticker {
    display: inline-block;
    padding-left: 100%;
    animation: ticker 60s linear infinite;
    /* animation-timing-function: linear; */
    animation-delay: 0s;
  }
  
  @keyframes ticker {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .ticker-item {
    display: inline-block;
    padding: 0 2rem; /* Adjust spacing between items */
    font-size: 2.5rem; /* Adjust font size as needed */
  }
  